import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.models';
import { Subscription } from 'rxjs';
import { IDataState } from '../../data/data.models';
import { GlobalLoadRequested } from '../../global.actions';
import { OperatorType } from '../../../common/criteria/criteria-operator-types.models';
import { operatorTypeToText } from '../../../common/criteria/criteria.util';
import { CriteriaOperator } from '../../../common/criteria/criteria.models';
import { Question } from '../../../common/questions/question.models';
import { IConnectionInfo } from '../../../common/opportunities.model';
import { generateConnectionInfo } from '../../../common/questions/connection-info-generator';
import * as _ from 'lodash';

@Component({
  selector: 'app-mock-define',
  templateUrl: './mock-define.component.html',
  styleUrls: ['./mock-define.component.sass']
})
export class MockDefineComponent implements OnInit, OnDestroy {
  data: IDataState;
  step = 0;

  categoryOptions: any;
  operatorTypes: OperatorType[] = [OperatorType.ONE_OF, OperatorType.ALL_OF];
  checkedType = OperatorType.ONE_OF;
  maxAges = [3, 6, 9, 12];
  selectedMaxAge = 6;

  // operator: CriteriaOperator = {
  //   type: OperatorTypes.ONE_OF,
  //   optionIds: [
  //     'AHelpCategoriesAcceleratorsIncubators',
  //     'AHelpCategoriesRegulatoryComplianceAndLicensing'
  //   ]
  // };
  question: Question;

  operatorOptions: any;
  operatorSelections: any;

  org: IConnectionInfo;

  filterLines = [
    {
      questionId: 'QWhichThemesApplyStronglyToYourProductOrService',
      name: 'Industries'
    },
    {
      questionId: 'QWhichIndustriesApplyStronglyToYourProductOrService',
      name: 'Themes'
    },
    {
      questionId: 'QTotalReceivedFromPrivateEquityInAustralia',
      name: 'Total amount raised'
    }
    // { questionId: 'OTHER', name: 'Other...' }
  ];

  selectedFilterQuestionId: string | null = null;

  private subscription: Subscription;
  private criterionSubscription: Subscription;

  constructor(private store: Store<AppState>) {
    this.store.dispatch(new GlobalLoadRequested());
    this.subscription = store.select(s => s.data).subscribe(data => {
      this.data = data;

      this.categoryOptions = data.sections.ids.map(id => {
        const section = data.sections.byId[id];
        return { id: section.id, text: section.title };
      });
      this.question =
        data.questions.byId['QWhichWillYouNeedInTheNextSixMonths'];
      if (this.question) {
        this.operatorOptions = (this.question as any).options;
        this.operatorSelections = {
          AHelpCategoriesAcceleratorsIncubators: true,
          AHelpCategoriesRegulatoryComplianceAndLicensing: true
        };
      }
      const responsesByKey = _.keyBy(
        _.values(data.responses.byId),
        'questionKey'
      );
      console.log('r', data.responses.byId);
      const questionsByKey = _.keyBy(_.values(data.questions.byId), 'key');
      console.log(questionsByKey);
      this.org = generateConnectionInfo(
        '1234',
        false,
        responsesByKey,
        questionsByKey
      );

      console.log(this.org);
    });
  }

  ngOnInit() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.onKeyDown);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  inc() {
    this.step++;
  }

  dec() {
    this.step--;
    if (this.step < 0) {
      this.step = 0;
    }
  }

  operatorTypeDisplayName(op: OperatorType) {
    return operatorTypeToText(op);
  }

  onKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        this.dec();
        break;
      case 'ArrowRight':
        this.inc();
        break;
    }
  };

  selectFilter(fi: any) {
    if (fi.questionId === this.selectedFilterQuestionId) {
      this.selectedFilterQuestionId = null;
    } else {
      this.selectedFilterQuestionId = fi.questionId;
    }
  }
}
